.workex_main {
  background: rgb(0, 0, 0);
  background: linear-gradient(25deg, rgb(0, 21, 67) 0%, rgb(0, 0, 0) 60%);
  min-height: 100vh;
  padding-top: 5rem;
  color: white;
}

.r_title {
  text-align: center;
  font-family: "Cinzel";
  font-size: 4rem;
  padding-top: 1rem;
}

.rc1,
.rc2 {
  width: 100vw;
  display: block;
}
.rc2 {
  margin-top: 2rem;
}
.rc1_left {
  display: inline-block;
  width: 50%;
}

.rc2_left {
  display: inline-block;
  width: 50%;
}
.rc2_right {
  display: inline-block;
  width: 50%;
}
.moneyflo_logo {
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
}

.rc1_right {
  display: inline-block;
  width: 40%;
  vertical-align: top;
}

.about_comp {
  text-align: center;
  font-family: "Cinzel";
  font-size: 2rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
}
.about_content {
  text-align: left;
  font-family: "Roboto";
  font-size: 1.25rem;
}

.work_img_box_2 {
  width: 20vw;
  height: 20vw;
  margin-left: 10vw;
}

.img_container {
  background-color: rgba(255, 255, 255);
  width: 100%;
  height: 100%;
  margin-left: 20%;
  border-radius: 50%;
  padding: 1rem;
  height: 100%;
  position: relative;
  z-index: 100;
}
#img_container_light {
  background-color: rgb(203, 209, 223) !important;
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.6);
  color: black !important;
}
#img_cont_bg_light {
  border: 2px solid rgba(0, 0, 0, 0.4);
}
.img_cont_bg {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 3px solid rgba(255, 255, 255, 0.85);
  border-radius: 50%;
  transform: translate(30%, -105%);
}
.mywork {
  text-align: center;
  font-family: "Cinzel";
  font-size: 2rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.skillsH {
  text-align: center;
  font-family: "Cinzel";
  font-size: 2rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.rc2_left,
.rc2_right {
  vertical-align: top;
}
.rc2_left p {
  text-align: left;
  font-family: "Roboto";
  font-size: 1.25rem;
  width: 70%;
  margin-left: 15%;
}

.work_dur {
  font-size: 1.2rem;
  font-family: "Cinzel", serif;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 4rem;
}

.work_calender {
  margin-right: 0.8rem;
  font-size: 1.2rem;
}

.skil_set {
  width: 90%;
  margin-left: 5%;
  text-align: center;
  align-items: center;
}

.work_skill {
  display: inline-block;
  margin: 2rem 1.5rem;
  padding: 0.7rem;
  width: 8rem;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 50px;
  box-shadow: 5px 3px 3px 2px rgb(255, 255, 255);
}

#lightid .work_skill {
  color: white;
  background-color: rgba(8, 0, 44, 0.92);
  box-shadow: 2px 4px 2px rgba(0, 6, 67, 0.788);
}

.rc1 {
  height: fit-content;
  height: max-content;
}

.website_link {
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.774);
  font-family: "Cinzel";
  font-size: 1rem;
}
#lightid .website_link {
  color: white;
  background-color: rgba(8, 0, 44, 0.92);
}
.link_icon {
  font-size: 1.25rem;
  margin-right: 0.6rem;
  transform: translateY(0.25rem);
}

@media only screen and (max-width: 660px) {
  .rc1_left,
  .rc1_right,
  .rc2_left,
  .rc2_right {
    display: block;
    width: 90vw;
    margin-left: 5vw;
  }

  .work_img_box {
    width: 50vw;
    height: 50vw;
    margin-left: 50%;
    transform: translateX(-75%);
  }
  .r_title {
    font-size: 8vw;
  }
  .work_dur {
    font-size: 4vw;
  }
  .about_comp {
    font-size: 6vw;
  }
  .mywork {
    font-size: 6vw;
  }
  .about_content {
    font-size: 4vw;
  }
  .rc2_left p {
    text-align: left;
    font-family: "Roboto";
    font-size: 4vw;
    width: 100%;
    margin-left: 0;
  }
  .skillsH {
    font-size: 6vw;
  }
  .website_link {
    padding: 0.5rem 1.25rem;
    font-size: 0.85rem;
  }

  .link_icon {
    font-size: 1rem;
    margin-right: 0.35rem;
    transform: translateY(0.15rem);
  }
  .skil_set {
    width: 100%;
    margin-left: 0%;
    padding-bottom: 2rem;
  }

  .work_skill {
    display: inline-block;
    margin: 1rem 1.5rem;
    padding: 0.7rem;
    width: 6rem;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 1rem;
    border-radius: 50px;
    box-shadow: 2px 3px 3px 2px rgb(255, 255, 255);
  }

  .img_cont_bg {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 3px solid rgba(255, 255, 255, 0.85);
    border-radius: 50%;
    transform: translate(36%, -105%);
  }
}
