
  .left {
    position: relative;
    z-index: 100;
    display: inline-block;
    width: 40vw;
    height: 40vh;
  }
  .me {
    width: 25rem;
    height: 25rem;
    box-shadow: 5px 3px 5px;
    border-radius: 10px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    position: relative;
    z-index: 200;
  }
  #light_me {
    box-shadow: 5px 3px 5px white;
  }
  
  #light_imgBg {
    border: 2px solid black;
  }
  .leftBg {
    border-radius: 5px;
    width: 22rem;
    height: 22rem;
    background-color: transparent;
    border: 2px solid white;
    transform: translate(40%, -130%);
  }
  
  .about_txt_new {
    font-size: 1.4rem;
    display: inline-block;
    height: 80vh;
    transform: translateY(-4rem);
    vertical-align: top;
    width: 50vw;
    font-family: "Roboto";
    position: relative;
    z-index: 10;
  }
  
  .download {
    margin-top: 4.5rem;
    margin-left: 30%;
    font-size: 1.75rem;
    background-color: white !important;
    font-family: "Roboto";
    padding: 1.3rem 1rem;
    padding-top: 0.6em;
    border-radius: 20px;
    width: 50%;
    position: relative !important;
    z-index: 2 !important;
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.5);
  }
  #download_light {
    background-color: rgba(8, 0, 44, 0.92) !important;
    color: white !important;
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.5);
  }
  .d_icon {
    margin-left: 0.25rem;
    font-size: 2rem;
    transform: translateY(15%);
  }
  #d_icon_light {
    margin-left: 0.25rem;
    font-size: 1.8rem;
    transform: translateY(15%);
    color: white;
  }

  
  @media only screen and (max-width: 600px) {
    .about_main {
      min-height: 100vh;
      height: auto;
    }
    .about_txt_new {
      width: 100vw;
      text-align: center;
      margin-top: 2rem;
      /* justify-content: center; */
    }
  
    .left {
      width: 100vw;
      position: relative;
      z-index: 100;
      display: inline-block;
      height: 50vw;
    }
  
    .me {
      width: 50vw;
      height: 50vw;
      box-shadow: 5px 3px 5px;
      border-radius: 5px;
      margin-left: 50%;
      transform: translate(-50%, -3rem);
      position: relative;
      z-index: 200;
    }
  
  
    .leftBg {
      width: 50vw;
      height: 50vw;
      transform: translate(52.5%, -120%);
    }
  
    .download {
    margin-top: 2rem;
margin-left: 0;
     font-size: 1.2rem;
     padding: 1rem;
     padding-top: 0.4em;
     border-radius: 10px;
      width: 65vw;
      display: block;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  
    #download_light {
      /* margin-top: 1.5rem;
      margin-left: 30%;
      font-size: 1.1rem;
      font-family: "Roboto";
      padding: 0.5rem 1rem;
      padding-top: 0.1rem;
      border-radius: 50px; */
    }
  
    .d_icon {
        margin-left: 0.35rem !important;
        font-size: 1.7rem !important;
        transform: translateY(20%) !important;
      }
    .about_main {
      min-height: 100vh;
    }
  }
  