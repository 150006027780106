.skillsPage_main {
  background: rgb(0, 0, 0);
  background: linear-gradient(25deg, rgb(0, 21, 67) 0%, rgb(0, 0, 0) 60%);
  padding-top: 5rem;
  margin-bottom: 0;
  padding-bottom: 0;
  color: white;
}
.skillsPage_header {
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  font-family: "Lato", sans-serif;
  margin-bottom: 2rem;
  font-family: "Cinzel", serif;
  padding-top: 2rem;
}

.skillsPage_text {
  text-align: center;
  font-size: 1.2rem;
  font-family: "Lato", serif;
  width: 50%;
  margin-left: 25%;
  font-weight: 400;
  font-style: italic;
}

.skillsPage_cards {
  text-align: center;
  margin: 5rem 0rem;
  margin-bottom: 0;
}

.skillsPage_cards .skills_card_container {
  margin-bottom: 6rem;
  margin-top: 2rem;
}

#light_star{
  color: rgb(21, 0, 92) !important; 
 }
 
@media only screen and (max-width: 600px) {
  .skillsPage_header {
    font-size: 6.5vw;
  }
  .skillsPage_text {
    width: 90vw;
    font-size: 1rem;
    margin-left: 5vw;
  }
  .skillsPage_cards .skills_card{
    margin-bottom: 2rem !important;
    height: 17rem !important;
  }
}
