.about_main {
  height: 100vh;
  background: rgb(0, 2, 49);
  background: linear-gradient(125deg, rgb(0, 21, 67) 0%, rgb(0, 0, 0) 60%);
  color: white;
}

.about_header {
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
  font-family: "Cinzel", serif;
  padding-top: 6rem;
}

.about_txt {
  font-size: 1.4rem;
  display: inline-block;
  width: 90vw;
  margin-left: 5vw;
  line-height: 2.7rem;
  font-family: "Roboto";
  font-weight: 200 !important ;
}

.letter {
  transition: 0.15s;
  font-size: 2.5rem;
}
.letter:hover {
  font-size: 3rem;
}

@media only screen and (max-width: 600px) {
  .about_main {
    min-height: 100vh;
    height: auto;
  }

  .letter:hover {
    font-size: 7.5vw;
  }

  .letter {
    font-size: 7.5vw;
  }
.about_header {
margin-bottom: 1rem;
height: auto;
}
  .about_txt {
    font-size: 1rem;
    display: inline-block;
    height: 50vh;
    width: 90vw;
    margin-left: 5vw;
    line-height: 1.5rem;
    font-family: "Roboto";
    font-weight: 200 !important ;
  }
}
