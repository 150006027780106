.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none; /* Initially hide the button */
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
}

.back-to-top-button.visible {
  display: block; /* Show the button when isVisible state is true */
}
.back-to-top-arrow {
  width: 50px;
  background-color: transparent;
  color: white;
  height: 50px;
  opacity: 0.75;
}

#arrow_light {
  color: rgb(6, 6, 35);
  opacity: 0.75;
}

@media only screen and (max-width: 600px) {
  .back-to-top-arrow {
    display: none;
  }
}
