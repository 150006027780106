@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Uchen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Silkscreen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Maze&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Silkscreen&display=swap");

.landing_main {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Uchen", sans-serif;
  background: rgb(0, 0, 0);
  background: linear-gradient(25deg, rgb(0, 21, 67) 0%, rgb(0, 0, 0) 60%);
  color: white;
  overflow-y: hidden;
}
body {
  overflow-x: hidden;
}

.landing_name {
  font-size: 3rem;
  position: relative;
  /* top: 35vh;
  left: 10%; */
  width: 100%;
  font-weight: 900;
  margin-top: 5rem;
  margin-bottom: 2rem;
  text-align: center;
  line-height: 110px;
  font-family: Silkscreen;
  font-family: "Rubik Maze", cursive;
  font-family: "Cinzel", serif;
}

.landing_profile {
  position: absolute;
  width: 40rem;
  opacity: 0.15;
  z-index: 100;
  margin-top: 6em;
  margin-left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .landing_profile {
    left: 50%;
    transform: translateX(-50%);
    top: 40vh;
    display: none;
    width: 80vw;
  }
  .landing_name {
    font-size: 7vw;
    margin-bottom: 5rem;
  }
}

#lightid {
background: rgb(237,237,237);
background: linear-gradient(223deg, rgba(237,237,237,0.7178221630449055) 50%, rgba(113,139,204,1) 99%);
  color: black ;
}
#lightid .landing_name{
  color: rgb(7, 1, 58) !important;
}