.projects_main {
  min-height: 100vh;
  background: rgb(0, 2, 49);
  background: linear-gradient(125deg, rgb(0, 21, 67) 0%, rgb(0, 0, 0) 60%);
  max-width: 100vw;
  color: white;
  overflow-x: hidden;
}

.projects_header {
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  font-family: "Cinzel", serif;
  padding-top: 4rem;
}

.card_c {
  text-align: center;
  align-items: center;
  padding: auto;
  margin-left: -3.5%;
}
.projcard_cont {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line for responsive design */
  gap: 1rem; /* Space between cards */
  justify-content: center; /* Aligns cards centrally */
  align-items: center;
  text-align: center;
  width: 100vw;
  display: inline-block;
}

.project_date{
  font-family: "Cinzel", serif;
  font-size: 0.9rem;
  font-family: 900;
  margin-top: 1rem;
  text-align: right;
  margin-right: 1rem;

}

.proj_img {
  width: 50%;
  z-index: 200;
  border-radius: 50%;
  padding: 0.5rem;
  border: 2.5px solid rgba(32, 70, 222, 0.5);
}
#proj_img_light{
  border: 2.5px solid rgba(0, 23, 106, 0.5) !important;

}
.proj_title {
  font-family: "Cinzel", serif;
  font-size: 1.5rem;
  font-family: 900;
  margin-top: 1rem;
  text-align: center;

}
.proj_text {
  margin-top: 1rem;
  font-family: "Roboto";
  font-style: italic;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: left;
}
.proj_card {
  width: 20rem;
  height: 25rem;
  background-color: rgb(5, 0, 54);
  margin: 0rem 4.5rem;
  box-shadow: 2px 6px 10px 6px rgb(0, 0, 0);
  padding-top: 2rem;
  position: relative;
  color:  white ; 
  z-index: 100;
}

.project_card {
  width: 20rem;
  margin: 0rem 4rem; /* Adjust spacing as needed */
  box-shadow: 2px 6px 10px 6px rgba(154, 161, 211, 0.492);
  padding-top: 2rem;
  position: relative;
  color: white;
  z-index: 100;
  display: flex; /* Enables flex layout inside card */
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Push links container to the bottom */
  height: 85%; /* Ensure all cards align */
}


#proj_card_light {
  background-color: rgb(210, 228, 243) !important;
  color: black !important;
}
#proj_card_light *{
  color: black !important;

}
.inner_card {
  width: 19.25rem;
  height: 25rem;
  border: 3px solid rgba(27, 57, 176, 0.5);
  background-color: rgba(27, 57, 176, 0.5);
  margin-left: 3.5rem;
  margin-top: 1.2rem;
  position: relative;
  transform: translateY(-100%) translateX(2.5rem);
}
.img_card:hover .proj_card {
  cursor: pointer;
  height: 26rem;
}

.pcard {
  display: inline-block;
}

.picon {
  font-size: 2rem;
  margin: auto;

}
.icons_c {
  margin-top: auto; /* Pushes the container to the bottom */
  display: flex;
  justify-content: center;
}

.icons_c2 {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 60%;
  margin-left: 20%;
}

.mainc {
  flex: 1 1 calc(20rem + 2rem); /* Flexible width with a minimum size */
  display: flex;
  flex-direction: column;
}

.morep {
  text-align: center;
  font-size: 1.1rem;
  font-family: "Cinzel", serif;
  margin-bottom: 2rem;
  width: 100vw;
}
#lightid a {
  color: black;
}
@media only screen and (max-width: 600px) {
  .projects_header {
    font-size: 8vw;
  }
  .card_c {
    width: 100vw;
    margin-left: 0rem !important;
  }
  .mainc {
    transform: translateX(-7.5%);
  }
  .morep {
    /* width: max-content; */
    /* margin-left: 100vw; */
    /* transform: translateX(-120%); */
    font-size: 0.25vw;
  }
  #more_link {
    font-size: 4vw;
  }
  .proj_card {
    width: 17rem !important;
    height: 23rem !important;
    background-color: rgb(5, 0, 54);
    margin: 0rem 4.5rem;
    box-shadow: 2px 6px 10px 6px rgb(0, 0, 0);
    padding-top: 2rem;
    position: relative;
    z-index: 100;
  }
  .inner_card {
    width: 16.25rem !important;
    height: 23rem !important;
    border: 3px solid rgba(27, 57, 176, 0.5);
    background-color: rgba(27, 57, 176, 0.5);
    margin-left: 3.5rem;
    margin-top: 1.2rem;
    position: relative;
    transform: translateY(-100%) translateX(2.5rem);
  }
  .proj_title{
    font-size: 1.25rem !important;
  }
  .proj_text{
    font-size: 1rem !important;

  }
  .picon{
    font-size: 1.5rem !important;
  }
}
